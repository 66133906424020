<template>
  <div class="ods-header">
    <svg-moovup-logo-mobile class="moovup-logo-mobile" />

    <div class="ods-header-btn">
      <div class="ods-triangle"></div>
      <p class="ods-label">
        {{ $t('ods.mobileLabel') }}
      </p>
      <span class="ods-title">{{ $t('ods.mobileTitle') }}</span>
      <a
        :href="`${odsUrl}?utm_source=employer_portal&utm_medium=web&utm_campaign=entrypoint`"
        target="_blank"
        class="ods-btn"
        >{{ $t('ods.mobileButton') }}</a
      >
      <a
        class="relative"
        :href="`${odsUrl}/message-inbox?utm_source=employer_portal&utm_medium=web&utm_campaign=message_notification`"
        target="_blank"
      >
        <svg-chat class="h-8 w-8" />
        <span v-if="unreadChatMessageCount" class="ods__chatcount">
          {{ unreadChatMessageCount }}
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import MoovupLogoMobile from '../../assets/svg/moovupLogo_mobile.svg'
import SvgChat from '../../assets/svg/chat.svg'
export default {
  components: {
    'svg-chat': SvgChat,
    'svg-moovup-logo-mobile': MoovupLogoMobile,
  },
  computed: {
    unreadChatMessageCount() {
      if (!this.totalUnreadChatMessageCount) {
        return ''
      }
      return this.totalUnreadChatMessageCount > 99
        ? '99+'
        : `${this.totalUnreadChatMessageCount}`
    },
  },
  data() {
    return {
      odsUrl: process.env.EMPLOYER_WEB__ODS_URL,
      totalUnreadChatMessageCount:
        this.$store.getters['profile/totalUnreadChatMessageCount'],
    }
  },
}
</script>

<style scoped lang="postcss">
.ods-header {
  @apply items-center justify-between flex bg-white shadow-xs z-30;
}

.moovup-logo-mobile {
  @apply ml-4;
  flex-shrink: 0;
  width: 40px;
}

.ods-header-btn {
  @apply relative flex items-center bg-indigo-100 h-12 pr-4;
}

.ods-triangle {
  position: absolute;
  top: -5px;
  left: -35px;
  width: 0;
  height: 0;
  border-left-width: 32px;
  border-bottom-width: 32px;
  border-bottom-color: theme('colors.indigo.100');
  border-left-color: transparent;
  border-right-width: 32px;
  border-right-color: transparent;
  transform: rotate(58deg);
}

.ods-label {
  @apply relative z-1 text-red-500 text-sm font-bold mr-1;
}

.ods-title {
  @apply text-black text-sm font-bold mr-1;
}

.ods-btn {
  @apply flex items-center bg-indigo-500 h-8 px-3 rounded-lg text-white text-sm no-underline font-bold mr-1;
}

.ods__chatcount {
  position: absolute;
  top: -5px;
  right: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 14px;
  font-size: 10px;
  @apply bg-red-500 text-white rounded-full p-1;
}

@screen desktop {
  .ods-header {
    @apply hidden;
  }
}
</style>
