<template>
  <m-drop-down :active="!companySuspended" :items="items">
    <b-post-job slot="button" :active="!companySuspended" />
  </m-drop-down>
</template>

<script>
import DropDownMenu from '../menu/dropDownMenu'
import { getFeatureFlag } from '../../libs/featureFlag'
import PostJobButton from '../button/postJobButton'

export default {
  components: {
    'b-post-job': PostJobButton,
    'm-drop-down': DropDownMenu,
  },
  computed: {
    /**
     * Menu items
     */
    items() {
      const createJobPath = '/jobs/post/info/'
      const showODS =
        getFeatureFlag('ODS') &&
        this.$store.getters['profile/brApproved'] &&
        !this.$store.getters['profile/companySuspended']

      return [
        {
          linkPath: createJobPath,
          linkQuery: {
            et: 'full-time',
            event_source: this.eventSource,
          },
          title: this.$t('employmentType.FullTime'),
        },
        {
          linkPath: createJobPath,
          linkQuery: {
            et: 'part-time',
            event_source: this.eventSource,
          },
          title: this.$t('employmentType.PartTime'),
        },
        {
          linkPath: createJobPath,
          linkQuery: {
            et: 'temp',
            event_source: this.eventSource,
          },
          title: this.$t('employmentType.Temp'),
        },
        ...(showODS
          ? [
              {
                href: `${process.env.EMPLOYER_WEB__ODS_URL}?utm_source=employer_portal&utm_medium=web&utm_campaign=create_ods_job`,
                new: true,
                target: '_blank',
                title: this.$t('ods.createJobButton'),
              },
            ]
          : []),
      ]
    },
  },
  data() {
    return {
      /**
       * Event source
       */
      eventSource: 'nav.post_job_button',
    }
  },
  props: {
    /**
     * Whether company is suspended
     */
    companySuspended: {
      default: false,
      type: Boolean,
    },
  },
}
</script>

<style lang="postcss" scoped>
.m.m--dropdown::v-deep {
  & .m__list {
    width: 202px;
  }
}

@screen mobile {
  .m.m--dropdown >>> .m__list {
    @apply top-auto left-auto right-0 pt-0 pb-2;
    width: 176px;
    bottom: 100%;
  }
  .m.m--dropdown:not(:hover) >>> .m__list {
    transform: translateY(3rem);
  }
}
</style>
