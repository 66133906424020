import { captureException, configureScope } from '@sentry/browser'
import { postOcto } from '..'

/**
 * POST *Graphql* request to Octo endpoint,
 * return extracted JSON data
 * @param {string} query
 * @param {Map<string, *>} variables
 * @param {string} token
 */
export async function postEmployerGQL(query, variables, token) {
  const res = await postOcto(
    'employer',
    {
      query,
      variables,
    },
    token,
  )

  // Unwrap graphql result from the top *data* layer
  if (res && res.data && res.data.data) {
    // If the result is empty,
    // the request has triggered schema error in BE,
    // send exception to sentry
    if (!Object.keys(res.data.data).length) {
      const errorMsg = 'GraphQL schema error'
      configureScope((scope) => {
        scope.setExtra('query', query)
      })
      captureException(new Error(errorMsg))

      return {
        error: {
          payload: errorMsg,
        },
      }
    }

    // Otherwise return result
    return {
      data: res.data.data,
    }
  }

  return res
}

/**
 * POST *Graphql* request to Octo endpoint,
 * return extracted JSON data
 * @param {string} query
 * @param {Dictionary<*>} variables
 * @param {string} token
 */
export async function postSeekerGQL(query, variables, token) {
  const res = await postOcto(
    'seeker',
    {
      query,
      variables,
    },
    token,
  )

  // Graphql result is wrapped in a *data* layer by default
  if (res && res.data && res.data.data) {
    // If the result is empty,
    // the request has triggered schema error in BE,
    // send exception to sentry
    if (!Object.keys(res.data.data).length) {
      const errorMsg = 'GraphQL schema error'

      configureScope((scope) => {
        scope.setExtra('query', query)
      })
      captureException(new Error(errorMsg))
      return {
        error: {
          payload: errorMsg,
        },
      }
    }

    // Otherwise return result
    return {
      data: res.data.data,
    }
  }

  return res
}

/**
 * POST *Graphql* request to Octo endpoint,
 * return extracted JSON data
 * @param {string} query
 * @param {Dictionary<*>} variables
 * @param {string} token
 */
export async function postODSEmployerGQL(query, variables, token) {
  const res = await postOcto(
    'ods-employer',
    {
      query,
      variables,
    },
    token,
  )

  // Graphql result is wrapped in a *data* layer by default
  if (res && res.data && res.data.data) {
    // If the result is empty,
    // the request has triggered schema error in BE,
    // send exception to sentry
    if (!Object.keys(res.data.data).length) {
      const errorMsg = 'GraphQL schema error'

      configureScope((scope) => {
        scope.setExtra('query', query)
      })
      captureException(new Error(errorMsg))
      return {
        error: {
          payload: errorMsg,
        },
      }
    }

    // Otherwise return result
    return {
      data: res.data.data,
    }
  }

  return res
}
